:root {
  --gray-400: #82868c;
  --black-01: #000000;
  --black-02: #2a3547;
  --gray-01: #808080;
  --gray-02: #dfe5ef;
  --gray-03: #d4d4d4;
  --gray-04: #f2f2f2;
  --gray-05: #e5eaef;
  --primary: #0074ba;
  --primary-light:#eff9ff;
  --gray-secondary: #edf0f6;
  --red-01: #fa896b;
  --header-table-color: #eff9ff;
  --text-color: #2a2a2a;
}
