.date_class {
  border-color: var(--grey-500);
}

.ellipsis-2line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.area-error {
  border-color: var(--red-01) !important;
}

.required::after {
  content: '*';
  color: var(--red-01);
}

*::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--gray-400) !important;
}
*::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--gray-400) !important;
}
*:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--gray-400) !important;
}
*:-moz-placeholder {
  /* Firefox 18- */
  color: var(--gray-400) !important;
}
.MuiChip-colorDefault {
  background-color: var(--gray-03) !important;
}
.Mui-disabled.MuiButton-root.MuiButton-contained {
  box-shadow: none !important;
}
.MuiChip-root {
  border-radius: 6px !important;
}
.MuiContainer-root {
  padding-top: 24px !important;
}
.MuiButtonBase-root.MuiTab-root {
  min-width: 0px !important;
  min-height: 0px !important;
  padding: 6px 8px !important;
}
